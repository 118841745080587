import HeadingChip from '@/@core/components/HeadingChip';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import first from '../../../public/images/landing/agent/1.svg';
import second from '../../../public/images/landing/agent/2.svg';
import third from '../../../public/images/landing/agent/3.svg';
import Image from 'next/image';
import AgentIcon from '@/@core/icons/landing/solution/AgentIcon';
import BookDemoButton from '@/@core/components/BookDemoButton';

// const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

const data = [
  {
    icon: <Image src={first} alt='AI Icon' />,
    heading: 'Improved Productivity',
    subHeading:
      'By automating manual tasks and managing everything from initial contact to final payment end to end, she reduces workload on your agents allowing more focus on higher value tasks.',
  },
  {
    icon: <Image src={second} alt='AI Icon' />,
    heading: 'Automated Dunning Efforts',
    subHeading:
      'Once accounts are assigned to Alice, she begins the recovery process begins immediately with self adjusting communication, requiring no manual intervention.',
  },
  {
    icon: <Image src={third} alt='AI Icon' />,
    heading: 'Collection Efforts',
    subHeading:
      'Alice analyzes customer profiles, characteristics, and engagement to formulate strategies that would be most effective for that debtor profile.',
  },
];

const AiAgent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        background: 'rgba(100, 97, 243, 0.15)',
        py: '80px',
      }}
    >
      <Box
        sx={{
          width: '1000px',
          maxWidth: '90vw',
          margin: '0 auto',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <HeadingChip text='AI Agent Alice' icon={AgentIcon} />
        <Typography
          sx={{
            color: '#1C1F25',
            fontSize: '4rem',
            fontWeight: '700',
            pt: '36px',
            pb: '34px',
            lineHeight: 1,
          }}
        >
          Meet Alice
        </Typography>
        <Typography
          sx={{
            color: '#3E3E3E',
            fontSize: '1.6rem',
            fontWeight: '400',
            pb: '16px',
            maxWidth: '500px',
            textAlign: 'center',
          }}
        >
          From the first contact to final payment, Alice automates it all, so your team can focus on what really
          matters.{' '}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '683px',
            gap: '12px',
            mt: '24px',
            '& .MuiButtonBase-root ': {
              padding: '12px 20px !important',
              fontSize: '1.6rem !important',
              fontWeight: '700 !important',
              color: '#fff',
              '&:hover': {
                background: '#1F1933',
              },
            },
          }}
        >
          <BookDemoButton variant='tertiary' text='Get a demo' />
          <Box
            sx={{
              '& .MuiButtonBase-root ': {
                color: '#4B5162 !important',
                background: '#fff !important',
              },
            }}
          >
            <BookDemoButton variant='tertiary' text='Talk to Sales' />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '32px',
            rowGap: '32px',
            flexWrap: 'wrap',
            justifyContent: 'center',
            mt: '48px',
            pb: '20px',
            mb: '36px',
          }}
        >
          {data.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  width: '290px',
                  padding: '16px',
                  borderRadius: '12px',
                  background: '#fff',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    pb: '10px',
                  }}
                >
                  {item.icon}
                  <Typography
                    sx={{
                      color: '#1F1933',
                      fontSize: '1.6rem',
                      fontWeight: '600',
                    }}
                  >
                    {item.heading}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: '#6F6F6F',
                    fontSize: '1.4rem',
                    fontWeight: '400',
                  }}
                >
                  {item.subHeading}
                </Typography>
              </Box>
            );
          })}
        </Box>
        {/* <Lottie animationData={Alice} loop={true} /> */}
      </Box>
    </Box>
  );
};

export default AiAgent;
