import HeadingChip from '@/@core/components/HeadingChip';
import UsersIcon from '@/@core/icons/landing/solution/Users';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import main from '../../../public/images/landing/users/main.svg';
import statisticOne from '../../../public/images/landing/users/statistic-1.svg';
import statisticTwo from '../../../public/images/landing/users/statistic-2.svg';
import statisticThree from '../../../public/images/landing/users/statistic-3.svg';
import statisticFour from '../../../public/images/landing/users/statistic-4.svg';
import Image from 'next/image';

const Data = [
  {
    subHeading: 'FOR TEAMS',
    heading: 'Unlock Efficiency with Alice, while you focus on what matters.',
    description:
      'Empower your finance team with our AI-powered collection partner, Alice to streamline operations & gain critical insights. Focus on strategic initiatives while Alice handles the legwork - recover cash faster and improve your bottom line.',
    firstImage: statisticOne,
  },
  {
    subHeading: 'FOR BOTTOMLINE',
    heading: 'Automate Small Invoice Collections and Safeguard Your Cash Flow',
    description:
      '82% of business failures link back to poor cash flow, often caused by uncollected "small" invoices! Even seemingly insignificant late payments can significantly impact your company’s bottom line. We automate these while you focus on scaling your business.',
    firstImage: statisticFour,
    secondImage: statisticThree,
  },
  {
    subHeading: 'FOR LEADERS',
    heading: 'Get Real-time insights in minutes, not hours',
    description:
      'Our plug-and-play platform automates tasks, generates personalized communication, and provides real-time risk analysis. Increase team productivity by 5x, reduce costs, and ensure smoother cash flow',
    firstImage: statisticTwo,
  },
];

const OurUsers = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        background: 'rgba(100, 97, 243, 0.15)',
        py: '80px',
      }}
    >
      <Box
        sx={{
          width: '1200px',
          maxWidth: '90vw',
          margin: '0 auto',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <HeadingChip text='Our Users' icon={UsersIcon} />
        <Typography
          sx={{
            color: '#1C1F25',
            fontSize: '4rem',
            fontWeight: '700',
            pt: '16px',
            pb: '10px',
            lineHeight: 1,
            ...(isMobile && {
              textAlign: 'center',
            }),
          }}
        >
          One Platform. Multiple Users
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '683px',
          }}
        >
          <Typography
            sx={{
              color: '#4B5162',
              fontSize: '1.6rem',
              fontWeight: '400',
              textAlign: 'center',
            }}
          >
            Empower your team to focus on high-value tasks by reducing manual effort and managing finance operations
            more efficiently while reducing operational costs
          </Typography>
        </Box>
        <Box sx={{ mt: '80px', display: 'flex', flexDirection: 'column', gap: '32px' }}>
          {Data.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  gap: '80px',
                  width: '1172px',
                  maxWidth: '90vw',
                  justifyContent: 'space-between',
                  padding: '32px',
                  border: '1px rgba(100, 97, 243, 0.49)',
                  borderRadius: '16px',
                  background: '#fff',
                  minHeight: '408px',
                  ...(index % 2 !== 0 && {
                    flexDirection: 'row-reverse',
                  }),
                  ...(isMobile && {
                    flexDirection: 'column',
                  }),
                }}
              >
                <Box sx={{ width: '480px', maxWidth: '100%' }}>
                  <Typography
                    sx={{
                      color: '#1C1F25',
                      fontSize: '1.6rem',
                      fontWeight: '500',
                    }}
                  >
                    {item.subHeading}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#1C1F25',
                      fontSize: '3.2rem',
                      fontWeight: '700',
                      py: '28px',
                    }}
                  >
                    {item.heading}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#5C5C5C',
                      fontSize: '1.6rem',
                      fontWeight: '400',
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '403px',
                    height: '300px',
                    borderRadius: '16px',
                    backgroundColor: theme.palette.primary.main,
                    position: 'relative',
                    maxWidth: '70vw',
                    ...(index % 2 !== 0 && {
                      ml: '32px',
                      mt: '32px',
                    }),
                    '& .our-users-image': {
                      transition: '0.5s all',
                    },
                    '&:hover': {
                      '& .our-users-image': {
                        transform: 'scale3d(1.2, 1.2, 1.2)',
                        right: '30px',
                        bottom: '15px',
                      },
                    },
                  }}
                >
                  <Box
                    className='our-users-image'
                    sx={{
                      position: 'absolute',
                      right: '0',
                      bottom: '-5px',
                      height: 'auto',
                      width: '300px',
                      borderRadius: '16px',
                      overflow: 'hidden',
                    }}
                  >
                    <Image src={main} alt='statistic dashboard' width={300} />
                  </Box>
                  {index === 0 && (
                    <Box
                      sx={{
                        position: 'relative',
                        bottom: isMobile ? '-13%' : '-44%',
                        left: isMobile ? '13%' : '-27%',
                        border: '1px solid #ECEEF4',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        padding: '20px 32px',
                        background: '#fff',
                        maxWidth: 'fit-content',
                        boxShadow:
                          '0px 0px 1px 0px rgba(44, 58, 114, 0.05), 0px 6px 8px 0px rgba(44, 58, 114, 0.05), 0px 18px 20px 0px rgba(44, 58, 114, 0.05), 0px 22px 32px 0px rgba(44, 58, 114, 0.05)',
                      }}
                    >
                      <Image src={item.firstImage} alt='statistic dashboard' />
                    </Box>
                  )}
                  {index === 1 && (
                    <Box
                      sx={{
                        position: 'relative',
                        top: '-10%',
                        left: '-10%',
                        border: '1px solid #ECEEF4',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        padding: '16px',
                        background: '#fff',
                        maxWidth: 'fit-content',
                        boxShadow:
                          '0px 0px 1px 0px rgba(44, 58, 114, 0.05), 0px 6px 8px 0px rgba(44, 58, 114, 0.05), 0px 18px 20px 0px rgba(44, 58, 114, 0.05), 0px 22px 32px 0px rgba(44, 58, 114, 0.05)',
                      }}
                    >
                      <Image src={item.firstImage} alt='statistic dashboard' />
                    </Box>
                  )}
                  {index === 1 && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '120px',
                        right: '-10%',
                        border: '1px solid #ECEEF4',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        padding: '16px',
                        background: '#fff',
                        maxWidth: 'fit-content',
                        boxShadow:
                          '0px 0px 1px 0px rgba(44, 58, 114, 0.05), 0px 6px 8px 0px rgba(44, 58, 114, 0.05), 0px 18px 20px 0px rgba(44, 58, 114, 0.05), 0px 22px 32px 0px rgba(44, 58, 114, 0.05)',
                      }}
                    >
                      <Image src={item.secondImage} alt='statistic dashboard' />
                    </Box>
                  )}
                  {index === 2 && (
                    <Box
                      sx={{
                        position: 'relative',
                        bottom: isMobile ? '-13%' : '-44%',
                        left: isMobile ? '13%' : '-27%',
                        border: '1px solid #ECEEF4',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        padding: '20px',
                        background: '#fff',
                        maxWidth: 'fit-content',
                        boxShadow:
                          '0px 0px 1px 0px rgba(44, 58, 114, 0.05), 0px 6px 8px 0px rgba(44, 58, 114, 0.05), 0px 18px 20px 0px rgba(44, 58, 114, 0.05), 0px 22px 32px 0px rgba(44, 58, 114, 0.05)',
                      }}
                    >
                      <Image src={item.firstImage} alt='statistic dashboard' width={285} />
                    </Box>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default OurUsers;
